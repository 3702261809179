@import "../../../../../../../assets/styles/Globals.scss";

.counter {
    width: auto;
    display: flex;
    margin: auto !important;

    &__increment {
        font-size: 25px !important;
        padding: 2px !important;
        line-height: normal !important;

        min-width: 45px !important;
        height: 30px !important;
    }

    &__decrement {
        font-size: 25px !important;
        padding: 2px !important;
        line-height: normal !important;
        min-width: 45px !important;
        height: 30px !important;
    }

    &__number {
        background-color: $colorPrimary;
        padding: 3px;
        width: 46px;
        text-align: center;
        color: white;
        border-radius: 8px;

        height: 30px;
    }

}