.InputDefault .MuiOutlinedInput-root {
  border-radius: $sizeBorderRadius;
  background-color: white;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 0 #0000, 0 0 #0000,
    0 1px 2px 0 rgb(0 0 0 / 0.05) !important;
}

.InputDefault .MuiOutlinedInput-input {
  // background-color: $backgroundDefault !important;
  border-radius: $sizeBorderRadius;
  font-size: $fontSizeDefault;
  padding: 8px 10px 11px 10px;
}

.InputDefault .MuiInputLabel-outlined {
  // transform: translate(11px, 12px) scale(1);
  // font-size: $fontSizeDefault;

  transform: translate(11px, 13px) scale(1);
  font-size: 11px !important;
}

.InputDefault
  .MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  // border: $sizeBorder $colorPrimary solid !important;
  //@include border-primary;
  border: 1px red solid !important;
}

.InputDefault .MuiOutlinedInput-notchedOutline {
  // border: $sizeBorder $colorBorder solid !important;
  //@include border-default;
  border: 1px hsl(0 0% 89.8%) solid !important;
}

.InputDefault .MuiFormLabel-root.Mui-focused {
  color: $colorTitle;
  font-weight: bolder;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(12px, -9px) scale(0.9);
  font-size: 14px !important;
}

.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  // padding: 0px !important
  padding-right: 33px !important;
}

.MuiInputLabel-outlined {
  font-size: 14px !important;
}

.title-password {
  transform: translate(14px, 17px) scale(1);
}

/* ICON */

.InputDefault {
  background-color: transparent !important;
}

.InputDefault .MuiOutlinedInput-adornedStart {
  padding-left: 10px;
}

.InputDefault .MuiInputAdornment-positionStart {
  margin-right: 0px;
}

.InputGroup__box {
  width: 100%;
  display: flex;
}


.css-fzem9i-MuiAutocomplete-root .MuiOutlinedInput-root{
  padding: 3px !important;
}