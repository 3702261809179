.errMono {
    animation: float infinite 3s ease-in-out;

    @keyframes float {
        0% {
            transform: translateY(0);
        }

        50% {
            transform: translateY(10px)
        }

        100% {
            transform: translateY(0px)
        }
    }
}