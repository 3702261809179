.ItemInput {
    display: flex;

    >.span {
        width: max-content;
        text-align: left;
        margin: auto 0px;
        text-transform: uppercase;
        font-size: 14px;
        margin-right: 2px;
    }
}

.KeyBoardNumber {
    width: 300px;
    margin: auto;

    &__tot {
        font-size: 30px !important;
        text-align: right;
        padding: 15px 10px !important;
        background-color: #eee;
    }

    &__numb {
        font-size: 20px !important;
        text-align: center;
        padding: 10px 5px;
        cursor: pointer;

        &:hover {
            background-color: #eee !important;
        }

        &:active {
            background-color: silver !important;
        }
    }
}