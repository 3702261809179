@import "../../../../../assets/styles/Globals.scss";

.moodPay {
    width: 100% !important;
    text-align: center;
    padding: 5px;
    cursor: pointer;

    &:hover {
        background-color: silver;
    }
}

.isSelectedMoodPay {
    background-color: $colorSecundary !important;
    color: white !important;
}