@import "../../assets/styles/Globals.scss";

.Grid-container-Pedidos {
    // margin-top: 10px

    .GridItemPedido {
        // background-color: red;
    }
}

.CardItemPedido {
    // background-color: red;

    .CardHeadPedido {
        background-color: $colorBackground_wallpaper;
        padding: 5px;
        font-size: 13px;
    }
}

.ListProduct__priority {
    // background-color: #ff00001f;

    -webkit-animation: blink 1s linear infinite;
    -moz-animation: blink 1s linear infinite;
    animation: blink 1s linear infinite;
}

.ListProduct {
    border: 1px #d5d5d5 solid;
    padding: 8px 3px;

    .__nombre {
        font-size: 14px;
        font-weight: 600;
    }

    .__estado {
        font-size: 12px;
        padding: 4px;
        margin: 0px auto;
        border-radius: 7px;
        text-align: center;
    }

    ._terminado {
        background: #056305;
        color: white;
    }

    ._proceso {
        background: #f0b248;
        color: #056305;
    }

    ._ingresado {
        background: #ff2f2f;
        color: #ffffff;
    }

    &:hover {
        background-color: $colorBackground_wallpaper;
        cursor: pointer;
    }
}

@keyframes blink {
    0% {
        background-color: #ff000066;
        box-shadow: 0 0 2px 0 rgb(145 158 171 / 24%), 0 16px 32px -4px rgb(145 158 171 / 24%);
        transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }

    100% {
        background-color: white;
    }
}

@-webkit-keyframes blink {
    0% {
        background-color: #ff000066;
        box-shadow: 0 0 2px 0 rgb(145 158 171 / 24%), 0 16px 32px -4px rgb(145 158 171 / 24%);
        transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }

    100% {
        background-color: white;
    }
}

.blink {
    -webkit-animation: blink 3s linear infinite;
    -moz-animation: blink 3s linear infinite;
    animation: blink 3s linear infinite;
}