.Login {
  display: inline-flex;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  // background-color: $colorBackground_wallpaper;
  background-color: whitesmoke;
  overflow: auto;

  &__Img {
    background-color: blue;
    width: 60%;
    height: auto;

    & img {
      width: auto;
      height: -webkit-fill-available;
      filter: brightness(0.8);
    }
  }

  &__Body {
    width: 440px;
    z-index: 1;

    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;

    > .Login_Container {
      backdrop-filter: saturate(180%) blur(20px);
      background-color: white;
      padding: 15px;
      text-align: center;
      width: 100%;
      border-radius: $sizeBorderRadius;
      //   box-shadow: 0 0 2px 0 rgb(145 158 171 / 24%),
      //     0 16px 32px -4px rgb(145 158 171 / 24%);
      //   transition: box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0ms;

      --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1) !important;
      --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color),
        0 1px 2px -1px var(--tw-shadow-color) !important;
      box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
        var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;

      > .Logo_Login {
        width: 189px;
        margin: auto;
        font-size: 27px;
        color: $colorPrimary;
        // margin-bottom: 10px;
      }

      > .Texto_Login {
        width: 80%;
        margin: auto;
        color: $colorSecundary;
      }

      > .Inputs_Container {
        width: 100%;
        margin: auto;
        margin-top: 10px;
        text-align: center;
      }
    }
  }
}

@media (max-width: $breakpoint-ipad) {
  .Login {
    &__Body {
      width: 70%;

      > .Login_Container {
        > .Logo_Login {
          width: 150px;
        }
      }
    }
  }

  @media (max-width: $breakpoint-phone) {
    .Login {
      &__Body {
        width: 90%;

        > .Login_Container {
          > .Logo_Login {
            width: 100px;
          }
        }
      }
    }
  }
}
