.MenuBar {
  width: 300px;
  padding: 20px 0px 20px 0px;

  & .ContainerMenu {
    height: 100%;
    border-radius: 0px 10px 10px 0px;
    // background: rgb(51 5 141 / 2%) !important;

    //@include border-default;

    box-shadow: 0 0 2px 0 rgb(145 158 171 / 24%),
      0 16px 32px -4px rgb(145 158 171 / 24%);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    backdrop-filter: saturate(180%) blur(20px);

    // & .itemNav {
    //     background-color: red !important;
    // }

    & .active,
    path {
      // background-color: $colorPrimary;
      background-color: hsl(0 0% 9% / 0.1) !important;
      color: $colorSecundary !important;
    }

    & .inactive,
    path {
      color: $colorSecundary !important;
    }

    & span {
      font-size: 13px !important;
    }
  }
}

.MenuBarAccount {
  width: 300px;
  padding: 20px 0px 20px 0px;

  & .ContainerMenu {
    height: 100%;
    border-radius: 0px 20px 20px 0px;
    background-color: rgba($colorSecundary, 0.2);

    & .active {
      color: white !important;
      background-color: $colorSecundary;
    }

    & .inactive {
      color: $colorSecundary;
    }

    & path {
      color: $colorSecundary;
    }

    & span {
      font-size: 13px !important;
    }
  }
}

.MuiBackdrop-root {
  -webkit-backdrop-filter: saturate(180%) blur(4px);
  backdrop-filter: saturate(180%) blur(4px);
  background-color: #ffffff47 !important;
}

.MuiListSubheader-sticky {
  background-color: transparent !important;
}

.MenuDatosResponsive {
  display: none !important;
}

.sectionMobile {
  display: none !important;
}

@media (max-width: $breakpoint-phone) {
  .MenuBar {
    display: none !important;
  }

  .sectionMobile {
    display: flex !important;
  }

  .MenuBarAccount {
    display: none !important;
  }

  .MenuDatosResponsive {
    display: flex !important;
  }
}
