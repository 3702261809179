@import "/src/assets/styles/Globals.scss";

.containerGrid {
    height: 100%;
}

.mainContainerTmp {
    position: absolute;
    right: 0px;
    bottom: 0px;
    top: 80px;
    left: 0px;
    margin: auto !important;
}

.rowOrder {
    cursor: pointer;
}

.selectedOrder {
    background-color: rgba($colorPrimary, 0.16);
    font-weight: 600 !important;
}