.container-split {
    padding: 5px;
    display: inline-flex;
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    top: 63px;

    &__left {
        width: 55%;
        padding: 5px;
        overflow: auto;
        height: 100%;
    }

    &__right {
        width: 45%;
        padding: 5px;
        overflow: auto;
        height: 100%;
    }
}