.containernumber {

    display: inline;

    .row_number {
        display: flex;
    }

    .number_input {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        margin: 5px;
        background: #f7f7f7;
        font-size: 18px;
        color: #1a1a1a;
        border: 2px #f5f5f5 solid;
    }
}

.key_desktop {
    display: inline;
}

.key_mobile {
    display: none;

    .number_input {
        margin: 3px;
        width: 60px;
        height: 55px;

        .MuiButton-text {
            padding: 4px 5px;
        }
    }

}


@media (max-width: $breakpoint-phone) {

    .key_desktop {
        display: none;
    }

    .key_mobile {
        display: inline-block;
    }

}