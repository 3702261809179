$opacity: 0.1;

@mixin background-opacity($color) {
  background-color: rgba($color, $opacity) !important;
}

.button-primary {
  @include background-opacity($colorPrimary);
  border: 1px desaturate($colorPrimary, 10%) solid !important;
  // border-bottom: 6px desaturate($colorPrimary, 20%) solid !important;
  border-radius: $sizeBorderRadiusButton !important;
  color: white !important;

  font-weight: 300 !important;
}

.button-default {
  @include background-opacity($colorSecundary);
  // border: 1px rgba(darken($colorSecundary, 2%), $opacity) solid !important;
  // border-bottom: 6px darken($colorSecundary, 15%) solid !important;
  border-radius: $sizeBorderRadiusButton !important;
  color: $colorPrimary !important;
  box-shadow: var(0 0 #0000, 0 0 #0000), var(0 0 #0000, 0 0 #0000),
    var(0 1px 2px 0 rgba(0, 0, 0, 0.05)) !important;

  font-weight: 600 !important;
}

.button-success {
  @include background-opacity($colorSuccess);
  border: 1px rgba(darken($colorSuccess, 2%), $opacity) solid !important;
  // border-bottom: 6px darken($colorSuccess, 15%) solid !important;
  border-radius: $sizeBorderRadiusButton !important;
  color: $colorSuccess !important;

  font-weight: 300 !important;
}

.button-danger {
  @include background-opacity($colorDanger);
  border: 1px darken($colorDanger, 2%) solid !important;
  // border-bottom: 6px darken($colorDanger, 25%) solid !important;
  border-radius: $sizeBorderRadiusButton !important;
  color: white !important;

  font-weight: 300 !important;
}

.button-add {
  @include background-opacity($colorSuccess);
  border: 1px rgba(darken($colorSuccess, 2%), $opacity) solid !important;
  border-radius: $sizeBorderRadiusButton !important;
  color: $colorSuccess !important;

  font-weight: 300 !important;
}

.button-less {
  background-color: rgba($colorDanger, $opacity) !important;
  border: 1px rgba(darken(darken($colorDanger, 2%), 2%), $opacity) solid !important;
  border-radius: $sizeBorderRadiusButton !important;
  color: $colorDanger !important;

  font-weight: 300 !important;
}

.button-car-default {
  background-color: rgba(darken($colorPrimary, 2%), $opacity) !important;
  border-radius: $sizeBorderRadiusButton !important;
  color: white !important;

  font-weight: 300 !important;
  padding: 0px !important;
  line-height: normal;
}

.button-transparent {
  color: $colorSecundary !important;
}
