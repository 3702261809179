@import "../../../assets/styles/Globals.scss";

.cntCategory {
    padding: 20px 15px;
    text-align: center;
    cursor: pointer;

    &:hover {
        background: $colorBackground_wallpaper;
    }
}

.navBackCartProduct {
    padding: 8px 0px 0px;
    margin-bottom: 14px !important;
    // position: sticky;
    // top: 73px;
    // z-index: 9;
}

.navSearchProduct {
    margin-top: 20;
    margin-bottom: 20;
    padding: 4px 8px 8px;
    position: sticky;
    top: 64px;
    z-index: 1;
    -webkit-backdrop-filter: saturate(180%) blur(20px) !important;
    backdrop-filter: saturate(180%) blur(10px) !important;
    background-color: #ffffff75 !important;
}

.CartButton {
    position: fixed !important;
    bottom: 15px;
    right: 15px;
    background-color: #ed6c03 !important;
    color: white !important;
}