.Container-Inputs {
    // cursor: pointer;
    // padding: 17px;

    &:hover {
        background-color: #eee;
    }
}

.InputGroup {
    display: block !important;

    &__box {
        margin-bottom: 10px;
        display: block;
    }

}