@import "../../../assets/styles/Globals.scss";
$opacity: 0.1;

@mixin background-opacity($color) {
    background-color: rgba($color, $opacity) !important;
}

.MuiTab-textColorPrimary.Mui-selected {
    color: $colorPrimary !important;
    font-weight: 600;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
    color: $colorPrimary !important;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
    color: $colorPrimary !important;
}

.mesaIcon {
    text-align: center;
    padding: 15px 10px;
    cursor: pointer;
    position: relative;

    >.persons {
        text-align: left;
        margin-top: 5;
        font-size: 13px;
    }

    &:hover {
        background-color: #eee;
    }
}

.mesaSelected {
    border: 1px $colorPrimary solid !important;
    @include background-opacity($colorPrimary);
    box-shadow: 0 0 2px 0 rgb(145 158 171 / 24%), 0 16px 32px -4px rgb(145 158 171 / 24%);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.mesaDisabled {
    cursor: auto !important;
    opacity: 0.4;

    &:hover {
        background-color: transparent !important;
    }
}

.mesaOcupada {
    background-color: red;
    position: absolute;
    font-size: 12px;
    top: 0px;
    left: 0px;
    right: 0px;
    border-radius: 3px 3px 0px 0px;
}

.mesaDisponible {
    background-color: green;
    color: white;
    position: absolute;
    font-size: 12px;
    top: 0px;
    left: 0px;
    right: 0px;
    border-radius: 3px 3px 0px 0px;
}

.reservaSelected {
    border: 1px $colorPrimary solid !important;
    background-color: $colorBackground_wallpaper !important;
}