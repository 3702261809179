.FormMyReservation {
  width: 100%;
  height: 100%;

  .FormMyReservation__title {
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  .FormMyReservation__subtitle {
    font-size: 15px;
    margin-bottom: 20px;
    text-align: left;
  }

  .FormMyReservation__input {
    width: 100%;

    padding: 10px;
    &:focus {
      outline: none;
    }
  }

  .FormMyReservation__button {
    width: 100%;
    &:focus {
      outline: none;
    }
  }
}

.MyReservation {
  width: 100%;
  height: 100%;

  .MyReservation__title {
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .MyReservation__table {
  }
}
