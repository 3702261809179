@import "../../../assets/styles/Globals.scss";

.rs-btn-primary {
  background-color: $colorPrimary !important;
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  background-color: $colorPrimary !important;
}

.rs-picker-popup
  .rs-calendar
  .rs-calendar-table-cell:hover
  .rs-calendar-table-cell-content {
  border-radius: calc(0.5rem - 2px);
  color: $colorPrimary !important;
  box-shadow: 0 0 rgba(0, 0, 0, 0), 0 0 rgba(0, 0, 0, 0), 0 0 rgba(0, 0, 0, 0),
    0 0 rgba(0, 0, 0, 0), 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
}

.rs-input-group-lg > .rs-input {
  font-size: 12px !important;
}

.rs-input-group.rs-input-group-inside {
  height: 37.69px !important;
}

.rs-input-group-lg.rs-input-group > .rs-input {
  height: auto !important;
}

.rs-input-group-lg.rs-input-group > .rs-input-group-addon {
  height: auto !important;
}
