.template-carta {
  width: 100%;
  height: max-content;

  &--header {
    &__logo {
      background-color: #1a1a1a;
      text-align: center;
      padding-top: 10px;
      padding-bottom: 10px;
      position: relative;

      img {
        width: 100px;
        height: auto;
        margin: auto;
      }
    }

    &__title {
      text-align: center;
      @extend .gradient;

      h1 {
        margin: 0px;
        font-size: 22px;
        padding: 8px 0px 8px 0px;
      }
    }
  }

  &--body {
    //local image
    background-image: url("../images/verde/background.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    padding-top: 20px;
    padding-bottom: 20px;

    &__size {
      width: 50%;

      display: flex;
      // flex-direction: column;
      align-items: center;
      justify-content: center;

      .list-images {
        margin-right: 20px;
        position: relative;
        z-index: 12;

        ul {
          list-style: none;
          padding: 0px;
          margin: 0px;
          margin-top: 50px;

          li {
            margin-bottom: 20px;
          }
        }
      }

      .form-inputs {
        margin-left: 20px;
      }
    }
  }

  &--footer {
    @extend .gradient;
    text-align: center;
    padding: 7px 0px 7px 0px;

    p {
      font-weight: 600;
    }
  }
}

.card-image {
  width: 300px;
  height: 160px;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  border: 4px rgba(252, 191, 42, 1) solid;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }

  .card-image--title {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    background-color: rgb(198 21 98 / 50%);
    color: white;
    padding: 3px 0px 3px 0px;
    text-align: center;
    font-weight: 600;
    font-size: 13px;
    backdrop-filter: blur(2px);
    border-radius: 0px 0px 7px 7px;

    h3 {
      margin: 8px 0px 8px 0px;
    }
  }
}

.card-special {
  width: 80%;
  margin: auto;
  display: flex;

  justify-content: center;
  border: 4px rgba(252, 191, 42, 1) solid;
  border-radius: 20px;
  background-color: #621c64;
  color: white;
  position: absolute;
  top: 0px;
  left: 30px;
  height: 90px;

  h3 {
    margin: 0px;
    margin-top: 14px;
  }

  .image1 {
    position: absolute;
    left: 10px;
    top: 17px;
    width: 20px;
  }

  .image2 {
    position: absolute;
    left: 15px;
    bottom: 12px;
    width: 80px;
  }

  .image3 {
    position: absolute;
    right: 13px;
    top: 2px;
    width: 8px;
  }

  .image4 {
    position: absolute;
    right: 10px;
    top: 15px;
    width: 20px;
  }
}

.button-reserve {
  background: rgb(252, 191, 42) !important;
  background: linear-gradient(
    90deg,
    rgba(252, 191, 42, 1) 0%,
    rgba(255, 223, 146, 1) 65%,
    rgba(252, 191, 42, 1) 100%
  ) !important;
  animation: gradient 3s ease infinite !important;
}

.gradient {
  background: rgb(252, 191, 42);
  background: linear-gradient(
    90deg,
    rgba(252, 191, 42, 1) 0%,
    rgba(255, 223, 146, 1) 65%,
    rgba(252, 191, 42, 1) 100%
  );
  animation: gradient 3s ease infinite;
}

.final {
  margin-top: 60px;
  margin-bottom: 60px;

  &--card {
    border: 5px solid #fcbf2a;

    &__title {
      text-align: center;
      background-image: url("../images/verde/final3.png");
      padding: 10px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;

      h1 {
        font-size: 4em;
        margin: 0px;
      }

      h2 {
        font-size: 3em;
        margin: 0px;
      }
    }

    &__body {
      padding: 20px;
      height: 400px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #621c64;
      position: relative;

      .message {
        width: 500px;
        height: auto;
      }

      .background {
        position: absolute;
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        width: 100%;
        height: 100%;
        padding: 20px;

        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }

  &--rrss {
    text-align: center;

    h3 {
      font-size: 1.5em;
      color: #fcbf00;
      margin: 0px;
      margin-top: 30px;
      margin-bottom: 10px;
    }

    &__list {
      list-style-type: none;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
      margin: 0px;
      padding: 0px;

      li {
        color: #fcbf00;
        display: flex;
        justify-items: center;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .listImages-hidden {
    display: none;
  }

  .form-inputs {
    margin-top: 25px;
  }

  .template-carta {
    &--body {
      &__size {
        width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .list-images {
          margin-right: 0px;
          margin-bottom: 20px;
        }

        .form-inputs {
          margin-left: 0px;
          width: 80%;
        }
      }
    }
  }

  .final {
    margin-top: 70px;
    margin-bottom: 70px;
    width: 90%;

    &--card {
      border: 5px solid #fcbf2a;

      &__title {
        text-align: center;
        background-image: url("../images/verde/final3.png");
        padding: 5px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;

        h1 {
          font-size: 2em;
          margin: 0px;
        }

        h2 {
          font-size: 2em;
          margin: 0px;
        }
      }

      &__body {
        padding: 20px;
        height: 300px;

        .message {
          width: 300px;
          height: auto;
        }

        .background {
          padding: 10px;
        }
      }
    }
  }
}
