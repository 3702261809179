/* Estilo base para tablas */
.markdown-table {
  border-collapse: collapse;
  width: 100%;
  margin: 1rem 0;
  font-size: 14px;
  text-align: left;
}

/* Bordes para la tabla */
.markdown-table th,
.markdown-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

/* Fondo diferente para los encabezados */
.markdown-table th {
  background-color: #f4f4f4;
  color: #333;
  font-weight: bold;
}

/* Alternar colores en filas */
.markdown-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.markdown-table tr:hover {
  background-color: #f1f1f1;
}
