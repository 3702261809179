/*
    Imports
*/


@import "./Globals.scss";
@import "./Home.scss";
@import "./Header.scss";
@import "./MenuBar.scss";
@import "./MenuInfo.scss";
@import "./Input.scss";
@import "./Login.scss";
@import "./Button.scss";
@import "./ContainerInput.scss";
@import "./InputGroup.scss";
@import "./Tabs.scss";
@import "./ContainerNumber.scss";

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.main {
  background-color: transparent;
  position: absolute;
  right: 0px;
  left: 0px;
  bottom: 0px;
  top: 0px;
  // background-color: $colorBackground_wallpaper;
}

.Container {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  top: 0px;
  display: inline-flex;
}

.MainContainer {
  width: 98%;
  margin: auto;
  margin-top: 10px;
  // height: 100%;
}

.BodyContainer {
  width: 100%;
  position: relative;
  height: 100%;
  overflow: auto;

  // width: 100%;
  // padding-top: 20px;
  // padding-bottom: 20px;

  // position: relative;
  // margin-top: 20px;
  // margin-bottom: 20px;
  // margin-left: 25px;
  // margin-right: 25px;
  // -webkit-backdrop-filter: saturate(180%) blur(20px);
  // backdrop-filter: saturate(180%) blur(20px);

  // background-color: rgba(255, 255, 255, .9);

  // border: 2px solid $colorBorder;
  // border-bottom: 6px solid $colorBorderBottom;
  // border-radius: $sizeBorderRadiusButton;
}

.divider-main {
  margin-top: 30px;
  margin-bottom: 30px;
}

.css-8ewcdo-MuiInputBase-root-MuiOutlinedInput-root {
  padding: 0px !important;
}

.makeStyles-root-1 {
  background-color: transparent;
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  border-radius: 10px !important;
  //@include border-default;
}

.css-tlc64q-MuiPaper-root-MuiDialog-paper {
  border-radius: 10px !important;
  //@include border-default;
}

.css-rnmm7m-MuiPaper-root-MuiDialog-paper {
  border-radius: 10px !important;
  //@include border-default;
}

.css-dsuxgy-MuiTableCell-root {
  border-bottom: 1px rgba($colorSecundary, 0.3) solid !important;
  // font-size: 12px !important;
}

.css-1h37f49-MuiTableCell-root {
  border-bottom: 1px rgba($colorSecundary, 0.3) solid !important;
}

.css-nc6t7a-MuiTableCell-root {
  border-bottom: 1px rgba($colorSecundary, 0.3) solid !important;
}

.css-vtdehq-MuiTableCell-root {
  border-bottom: 1px rgba($colorSecundary, 0.3) solid !important;
}

.css-1h37f49-MuiTableCell-root {
  background-color: #fbfafd !important;
}

.css-nc6t7a-MuiTableCell-root {
  background-color: #fbfafd !important;
}

.css-qrhmsx-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 4px !important;
}

.css-1ta26kx .MuiOutlinedInput-root .MuiAutocomplete-input {
  padding: 2px !important;
}

.MuiPaper-root {
  background-color: rgb(255 255 255 / 60%);
  -webkit-backdrop-filter: saturate(180%) blur(18px);
  backdrop-filter: saturate(180%) blur(18px);
}

.makeStyles-root-1 {
  background-color: transparent !important;
}

.makeStyles-root-25 {
  background-color: transparent !important;
}

.makeStyles-root-29 {
  background-color: transparent !important;
}

.rs-picker-popup{
  z-index: 10000 !important;
}

// .css-lc2yqr-MuiButtonBase-root-MuiIconButton-root {
//     position: absolute !important;
// }

@media (max-width: $breakpoint-phone) {
  .MainContainer {
    width: 90%;
  }

  .BodyContainer {
    // margin-left: 7px;
    // margin-right: 7px;
    // margin-top: 4px;
    // margin-bottom: 6px;
  }
}
