._estado {
    font-size: 12px;
    padding: 4px;
    margin: 0px auto;
    border-radius: 7px;
    text-align: center;
}

.__terminado {
    background: #056305;
    color: white;
}

.__proceso {
    background: #f0b248;
    color: #056305;
}

.__ingreso {
    background: #ff2f2f;
    color: #ffffff;
}

.__precuenta {
    background: blue;
    color: white;
}