@import "../../assets/styles/Globals.scss";

/*  
    HEAD
*/
.head {
    background-color: $colorBackground_wallpaper;
    text-align: center;
    padding: 20px;
    position: sticky;
    top: 0px;
    border-bottom: 1px #c7c7c7 solid;
    z-index: 1;
    -webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: saturate(180%) blur(20px);
    box-shadow: none !important;
}

.headCarta {
    // background-color: $colorBackground_wallpaper !important;
    -webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: saturate(180%) blur(20px);
    z-index: 2;
    position: sticky !important;
    top: 0 !important;
    box-shadow: none !important;
    border-bottom: 1px #e4e4e4 solid;
    background-color: #fff !important;
    // box-shadow: 0px 3px 10px 2px #00000017 !important;
}

.button__cart {
    color: green !important;

}

/*
    BODY
*/

.body {
    // padding: 20px;
    // background-color: $colorBackground_wallpaper;
}

/*
    FOOTER
*/

.footer {
    // background-color: $colorPrimary;
    color: "white";
}

.button__footer {
    color: white !important;
    border: 1px solid white !important;
}

.title__footer {
    padding: 20px;
    color: white;
    text-align: center;
}

.copyright__footer {
    padding: 20px;
    color: #ffffff82;
    text-align: center;
}

.carta {
    background-color: #eee;
}

.container-carta {
    background-color: #fff;
    width: 100%;
}

@media (min-width: 350px) {
    .container-carta {
        width: 100%;
        margin: auto;
    }
}

@media (min-width: 400px) {
    .container-carta {
        width: 100%;
        margin: auto;
    }
}

@media (min-width: 600px) {
    .container-carta {
        width: 70%;
        margin: auto;
    }
}

@media (min-width: 960px) {
    .container-carta {
        width: 50%;
        margin: auto;
    }
}

@media (min-width: 1280px) {
    .container-carta {
        width: 40%;
        margin: auto;
    }
}