.ContainerInput {
  width: 100%;
  font-size: 13px;
  border-radius: 10px;
  position: relative;
  // background: rgb(51 5 141 / 2%) !important;
  box-shadow: 0 0 2px 0 rgb(145 158 171 / 24%),
    0 16px 32px -4px rgb(145 158 171 / 24%);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  padding: 15px 0px 15px 0px;
  backdrop-filter: saturate(180%) blur(20px);
  background-color: white;
  //@include border-default;
  &_title {
    background-color: white;
    width: auto;
    padding: 2px 15px 10px 15px;

    > span {
      font-size: 18px;
    }
  }
}

.Input-Spacing {
  padding: 17px;
}

.ContainerInputPrimary {
  width: 100%;
  border-radius: 10px;
  position: relative;
  // background: rgb(51 5 141 / 2%) !important;
  // box-shadow: 0 0 2px 0 rgb(145 158 171 / 24%), 0 16px 32px -4px rgb(145 158 171 / 24%);
  // transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  padding: 15px 0px 15px 0px;
  // backdrop-filter: saturate(180%) blur(20px);
  // @include border-default;

  &_title {
    width: auto;
    padding: 2px 15px 10px 15px;

    > span {
      font-size: 18px;
    }
  }
}

.Input-Spacing {
  padding: 17px;
}
