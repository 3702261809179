.acctionButtons {
  font-size: 11px !important;
}

.disabled {
  opacity: 0.4;
}

.newOrder {
  border: 1px green solid !important;
  color: green !important;
}

.cancelOrder {
  border: 1px red solid !important;
  color: red !important;
}

.cancelProduct {
  border: 1px red solid !important;
  color: red !important;
}

.addProduct {
  border: 1px blue solid !important;
  color: blue !important;
}

.addProductNavigation {
  color: blue !important;
}

.splitAccountNavigation {
  color: #d88c00 !important;
}

.applyDiscount {
  border: 1px #d88c00 solid !important;
  color: #d88c00 !important;
}

.splitAccount {
  border: 1px #d88c00 solid !important;
  color: #d88c00 !important;
}

.printAccount {
  border: 1px darkslategrey solid !important;
  color: darkslategrey !important;
}

.infoClient {
  border: 1px deeppink solid !important;
  color: deeppink !important;
}

.payAccount {
  border: 1px mediumslateblue solid !important;
  color: mediumslateblue !important;
}

.changeGarzon {
  border: 1px darkcyan solid !important;
  color: darkcyan !important;
}

.changeTable {
  border: 1px cornflowerblue solid !important;
  color: cornflowerblue !important;
}

.joinTable {
  border: 1px deeppink solid !important;
  color: deeppink !important;
}
