@import "../../assets/styles/Globals.scss";

.containerCategory {
    width: 100%;
    height: 100%;
    display: flex;
}

.itemCategory {
    width: 100px;
    height: 100%;

    >.itemTitle {
        width: 100%;
        background-color: red;
    }

    >.itemBody {
        width: 100%;
        background-color: blue;
    }
}

.swipeableViews {
    border-radius: 10px;
    @include border-default
}