.Header {
  background-color: transparent;
  margin: 0px;
  box-shadow: none !important;
  position: sticky;
  top: 0px;
  backdrop-filter: blur(6px);
  z-index: 5;
}

.circleName {
  background-color: $colorSecundary;
  color: $colorPrimary;
  padding: 5px;
  border-radius: 50%;
  margin-left: 5px;
  text-transform: uppercase;
}