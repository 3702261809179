@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  /* -moz-osx-font-smoothing: grayscale; */
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

@keyframes move-left-right {
  0% {
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(100%);
    opacity: 1;
  }
  55% {
    opacity: 0;
    transform: translateX(100%);
  }
  56% {
    transform: translateX(-100%);
    opacity: 0;
  }
  57% {
    opacity: 1;
  }
  100% {
    transform: translateX(100%);
  }
}

@keyframes move-right-left {
  0% {
    transform: translateX(100%);
  }
  50% {
    transform: translateX(-100%);
    opacity: 1;
  }
  55% {
    opacity: 0;
    transform: translateX(-100%);
  }
  56% {
    transform: translateX(100%);
    opacity: 0;
  }
  57% {
    opacity: 1;
  }
  100% {
    transform: translateX(-100%);
  }
}


@layer utilities {
  .animate-move-left-right {
    animation: move-right-left 30s linear infinite;
  }
}