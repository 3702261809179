@import "../../../../../assets/styles/Globals.scss";

.listMiniCart {

    &__listitem {
        border-bottom: 1px $colorBorder solid;
        padding-top: 10px;
        padding-bottom: 10px;

        .imageItem {
            text-align: right;
            display: flex;

            .container__image {
                background-color: $backgroundDefault;
                width: 70px;
                text-align: center;
                padding: 4px;
                border-radius: $sizeBorderRadius;

                >img {
                    width: 30px !important;
                }
            }

            .container__title {

                padding: 3px;
                display: block;

                .__name {
                    font-size: 15px;
                    text-align: left;
                }

                .__price {
                    font-size: 13px;
                    // color: #646464;
                    text-align: left;
                    font-weight: bold;
                    color: red !important;
                }

                .__category {
                    text-align: left;
                    background: $colorPrimary;
                    width: fit-content;
                    padding: 4px;
                    font-size: 14px;
                    border-radius: 7px;
                    color: white;
                    margin-top: 4px;
                    margin-bottom: 10px;
                }

            }
        }

        .countItem {
            display: flex;

            .container__count {
                width: max-content;
                margin: auto;
            }
        }
    }
}