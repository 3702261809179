.MenuInfo {
    color: $colorBlack;
    position: absolute;
    right: 13px;
    top: 63px;
    // z-index: 1;
    width: 330px;
    padding: 6px;
    border-radius: $sizeBorderRadius;
    border: $sizeBorder $colorBorder solid;
    border-bottom: 5px $colorBorder solid;
    -webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: saturate(180%) blur(15px);
    // background-color: rgba(255, 255, 255, 0.31%);
    box-shadow: 0 2px 10px rgba(20, 20, 20, 0.2);
    background-color: white;

    >._HeaderMenu {
        text-align: center;

        >.circle {
            font-size: 32px;
            width: 68px;
            margin: auto;
            padding: 12px;
        }

        ._NameUser {
            font-size: 16px;
            margin-top: 5px;
            font-weight: 600;
            color: $colorPrimary
        }

        ._EmailUser {
            font-size: 13px;
            margin-top: 5px;
        }

        ._BtnEdit {
            margin-top: 10px;
            border-radius: $sizeBorderRadius;
        }

        ._Divider {
            margin-top: 10px;
            margin-bottom: 10px;
        }

        ._BtnLogout {
            background-color: $colorPrimary;
            color: white !important;
        }
    }

    >._HeaderOpcion {
        text-align: left;
        display: "inline-flex";
    }
}