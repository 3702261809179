/*
    Variables
*/
$colorBackground: #F1F1F1;
$colorBackground_wallpaper: hsl(0 0% 96.1%) !important;

$colorPrimary: #e0004d;
$colorSecundary: #260766;
$colorDefault: #a51890;

$colorSuccess: #00ac00;
$colorDanger: #ff4949;

$colorTitle: #4c4c4c;

/* STYLE */
$colorBorder: #e5e5e5;
$colorBorderBottom: #cecece;
$backgroundDefault: #f7f7f7;
$sizeBorder: 2px;
$sizeBorderRadius: calc(0.5rem - 2px) ;

$sizeBorderRadiusButton: 4px;
$colorBlack: #525050;
$colorWhite: #eeeeee;

$fontSizeTitleMain: 18px;
$fontSizeTitle: 13px;
$fontSizeDefault: 13px;
// $fontFamily: "Open Sans", sans-serif !important;

$breakpoint-phone: 600px;
$breakpoint-ipad: 750px;

@mixin border-default {
    border: 1px rgba($colorSecundary, 0.16) solid !important;
    // border-bottom: 4px rgba($colorSecundary, 0.22) solid !important;
}

@mixin border-primary {
    border: 2px rgba($colorPrimary, 0.30) solid !important;
    border-bottom: 4px rgba($colorPrimary, 0.35) solid !important;
}